import React, { useEffect } from "react";
import Particles from "react-tsparticles";
import "tsparticles-fireworks";

const Fireworks = () => {
  useEffect(() => {
    // Configuración de tsparticles para fuegos artificiales
    const particlesConfig = {
      background: {
        color: {
          value: "#000",
        },
      },
      fpsLimit: 60,
      interactivity: {
        detectsOn: "canvas",
        events: {
          resize: true,
        },
      },
      particles: {
        number: {
          value: 100,
        },
        shape: {
          type: "circle",
        },
        size: {
          value: 3,
        },
        move: {
          direction: "bottom",
          out_mode: "out",
        },
      },
      detectRetina: true,
      retina_detect: true,
      backgroundMask: {
        cover: {
          color: {
            value: "#fff",
          },
        },
      },
      emitters: {
        direction: "bottom",
        life: {
          count: 0,
          duration: 0.1,
          delay: 0.1,
        },
        rate: {
          quantity: 1,
          delay: 1,
        },
        size: {
          width: 100,
          height: 0,
        },
      },
    };

    const particlesInit = (main) => {
      main.addEmitter(1);
    };

    setTimeout(() => {
      window.tsParticles?.dom?.[0]?.destroy();
      window.tsParticles.init(".tsparticles", particlesConfig, particlesInit);
    }, 1000);

    return () => {
      window.tsParticles?.dom?.[0]?.destroy();
    };
  }, []);

  return <Particles id="tsparticles" className="tsparticles" options={{}} />;
};

export default Fireworks;
