import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";

function Home() {
  return (
    <>
      <div className="container mt-2 mb-2">
        <div className="row text-center">
          <div className="col-md-12 mt-3 mb-3 ">
            <div className="card mb-3">
              <div className="card-body">
                <h5 className="card-title store-title">
                  <Link to="/catalogo-iss/wb">Weinbrenner</Link>
                </h5>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-2 mb-2 ">
            <div className="card mb-3">
              <div className="card-body">
                <h5 className="card-title store-title">
                  <Link to="/catalogo-iss/bg">Bubblegummers</Link>
                </h5>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-2 mb-2 ">
            <div className="card mb-3">
              <div className="card-body">
                <h5 className="card-title store-title">
                  <Link to="/catalogo-iss/ns">Northstar</Link>
                </h5>
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-2 mb-2">
            <div className="card mb-3">
              <div className="card-body">
                <h5 className="card-title store-title">
                  <Link to="/catalogo-iss/bt">Bata</Link>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
