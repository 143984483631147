import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

function SearchForm(props) {
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate(`/catalogo-iss/${props.marca}/${props.tienda}?s=${query}`);
  };

  return (
    <form className="form-inline" onSubmit={handleSubmit} method="GET">
      <input
        type="text"
        className="input form-control rounded-pill"
        placeholder="Buscar por nombre, SKU o categoría"
        value={query}
        name="s"
        onChange={(e) => setQuery(e.target.value)}
      />
      <button type="submit" className="btn btn-primary">
        Buscar
      </button>
    </form>
  );
}

export default SearchForm;
