import React, { useState, useEffect, useRef } from "react";
import SlotCounter from "react-slot-counter";
import Cookies from "js-cookie"; // Importa la biblioteca js-cookie
import Select from "react-select";
import { BeatLoader } from "react-spinners";
import Modal from "react-modal";

import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Fireworks from "./Fireworks";

function getRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function Slot2() {
  const counterRef = useRef(null);
  const [animation, setAnimation] = useState("d-none");
  const [stores, setStores] = useState([]);

  const { idStore } = useParams();
  const [linkTerms, setTerms] = useState(null);
  const [myIdStore, setMyIdStore] = useState(null);
  const [listStores, setListStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);

  const [loading, setLoading] = useState(true);
  const { marca, setIdStore } = useParams();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [playAgain, setPlayAgain] = useState(false);
  const [buttonPlay, setButtonPlay] = useState("Concursar");
  const [showPrincipalButton, setShowPrincipalButton] = useState(true);
  const [email, setEmail] = useState("");
  const [folio, setFolio] = useState("");
  const [loadingAPI, setLoadingAPI] = useState(false);
  const [currentWon, setCurrentWon] = useState(
    "Completa tus datos para participar",
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  //  const [gameWom, setFolio] = useState("");
  const [session, setSession] = useState(Cookies.get("game_session"));
  const [playAgainDisabled, setPlayAgainDisabled] = useState(false); // Nuevo estado para deshabilitar el botón

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const Modal = ({ isOpen, toggleModal }) => {
    if (!isOpen) return null;
    console.log("SHOWMODAL");
    return (
      <div className="modal-backdrop">
        <div className="modal-content">
          <div className="row">
            <div className="form-group">
              <label htmlFor="email">Ingresa tu correo:</label>
              <input
                type="email"
                className="form-control"
                name="email"
                // value="ricardo@digitag.cl"
                placeholder="Ej: sorprendete@bata.cl"
                onChange={handleEmailChange}
              />
            </div>
            <div className="row mt-4 mb-4">
              <div className="col-md-5 col-5">
                <div className="form-group">
                  <label htmlFor="voucher">Tienda: </label>
                  <div className="selectStore">
                    <Select
                      // required="required"
                      value={selectedStore}
                      onChange={handleSelectChange}
                      // onChange={(selectedOption) =>
                      //   setSelectedStore(selectedOption)
                      // }
                      options={listStores.map((store) => ({
                        value: store.id_store,
                        label: store.id_store,
                      }))}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-7">
                <div className="form-group">
                  <label htmlFor="voucher">Ingresa tu número de boleta:</label>
                  <input
                    type="number"
                    className="form-control"
                    id="voucher"
                    name="voucher"
                    placeholder="Ej: 999321"
                    value={folio}
                    onChange={handleFolioChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <p>¡Tu premio ha sido reclamado con éxito!</p>
          <button onClick={toggleModal}>Cerrar</button>
        </div>
      </div>
    );
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const idStoreParam = encodeURIComponent(idStore);
    if (idStoreParam) {
      const selected = listStores.find(
        (store) => store.id_store === parseInt(idStoreParam),
      );
      if (selected) {
        setMyIdStore(idStoreParam);
        console.log("SELECCIONADO: " + idStoreParam);
        setSelectedStore({
          value: selected.id_store,
          label: selected.id_store,
        });
      }
    }
  }, [listStores]);

  useEffect(() => {
    // Lógica para obtener y establecer listStores
    Promise.all([fetch(`/json/${marca}.json`)])
      .then(([stores]) => Promise.all([stores.json()]))
      .then(([stores]) => {
        stores.sort((a, b) => a.id_store - b.id_store);

        setListStores(stores);
      });

    // Establecer el valor seleccionado en base al parámetro idStore
  }, [location.search]);

  const handleSelectChange = (selectedOption) => {
    setSelectedStore(selectedOption);
    //console.log(selectedOption);
    setMyIdStore(selectedOption.value);
    console.log(myIdStore);
  };

  const [value, setValue] = useState([
    <img
      className="itemCounter"
      height="45px"
      width="45px"
      src="https://bg.ps.digitag.cl/bata-concurso/img/steps/2x1.svg"
      alt=""
    />,
    <img
      className="itemCounter"
      height="45px"
      width="45px"
      src="https://bg.ps.digitag.cl/bata-concurso/img/steps/10.svg"
      alt=""
    />,
    <img
      className="itemCounter"
      height="45px"
      width="45px"
      src="https://bg.ps.digitag.cl/bata-concurso/img/steps/0.svg"
      alt=""
    />,
  ]);

  const [startValue, setStartValue] = useState([
    <img
      className="itemCounter"
      height="45px"
      width="45px"
      src="https://bg.ps.digitag.cl/bata-concurso/img/steps/2x1.svg"
      alt=""
    />,
    <img
      className="itemCounter"
      height="45px"
      width="45px"
      src="https://bg.ps.digitag.cl/bata-concurso/img/steps/10.svg"
      alt=""
    />,
    <img
      className="itemCounter"
      height="45px"
      width="45px"
      src="https://bg.ps.digitag.cl/bata-concurso/img/steps/40.svg"
      alt=""
    />,
  ]);

  useEffect(() => {
    if (marca !== null) {
      const body = document.querySelector("body");
      body.classList.remove(...body.classList);
      body.classList.add(marca);

      if (marca == "bg") {
        setTerms(
          "https://www.bubblegummers.cl/content/3-terminos-y-condiciones-de-uso",
        );
      } else if (marca == "wb") {
        setTerms(
          "https://weinbrenner.cl/content/6-politicas-de-privacidad-y-seguridad",
        );
      } else if (marca == "ns") {
        setTerms(
          "https://northstar.cl/content/3-terminos-y-condiciones-de-uso",
        );
      } else if (marca == "bt") {
        setTerms("https://www.bata.cl/politicas-de-privacidad");
      } else {
        setTerms("https://www.bata.cl/politicas-de-privacidad");
      }
    }

    // Cambiar el favicon según la clase del body
    if (document.body.classList.contains("wb")) {
      changeFavicon("https://weinbrenner.cl/img/favicon.ico");
    } else if (document.body.classList.contains("bg")) {
      changeFavicon("https://bubblegummers.cl/img/favicon.ico?1637328303");
    } else if (document.body.classList.contains("bt")) {
      changeFavicon(
        "https://batacl.vteximg.com.br/arquivos/batacl-favicon.ico",
      );
    } else if (document.body.classList.contains("ns")) {
      changeFavicon("https://northstar.cl/img/favicon.ico?1661443551");
    } else {
      changeFavicon(
        "https://batacl.vteximg.com.br/arquivos/batacl-favicon.ico",
      );
    }
  }, []);

  function changeFavicon(href) {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = href;
    document.getElementsByTagName("head")[0].appendChild(link);
  }

  function validateEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  function validateInputs() {
    return folio.trim() !== "" && validateEmail(email);
  }

  function disabledButton() {
    setButtonDisabled(true);
  }

  function handlePlayAgain() {
    // Agrega aquí la lógica específica para "Jugar nuevamente"
    getData();
  }

  function handlePlayAgain() {
    // Deshabilitar el botón mientras se espera la respuesta
    setPlayAgainDisabled(true);
    getData();
  }

  useEffect(() => {
    const counterElement = document.querySelector(".digitag-slot");

    const handleTransitionEnd = () => {
      setStartValue(value);
      setButtonDisabled(false);
      // setButtonPlay("Quiero este descuento");
      // setPlayAgain(true);
      setPlayAgainDisabled(false); // Habilitar el botón después de la transición
    };

    counterElement.addEventListener("transitionend", handleTransitionEnd);

    return () => {
      counterElement.removeEventListener("transitionend", handleTransitionEnd);
    };
  }, [value]);

  function startSlotAnimation() {
    if (counterRef.current) {
      counterRef.current.startAnimation();
    }
  }
  function getRandomMultiplier() {
    const multipliers = ["20", "30", "50"];
    const randomIndex = Math.floor(Math.random() * multipliers.length);
    return multipliers[randomIndex];
  }

  function wonGame() {
    // Supongamos que tienes algunos datos para enviar al servidor
    const localSession = Cookies.get("game_session");
    const emailParam = encodeURIComponent(email);
    const folioParam = encodeURIComponent(folio);
    const brandParam = encodeURIComponent(marca);
    setLoadingAPI(true);
    // const idStoreParam = encodeURIComponent(idStoreParam);

    // Configuramos la solicitud POST
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    };

    setShowPrincipalButton(true);
    // Realizamos la solicitud
    fetch(
      `https://bg.ps.digitag.cl/ps/internal/save_concurso/?save=1&idStore=${myIdStore}&store=${brandParam}&session=${localSession}&email=${emailParam}&folio=${folioParam}`,
      requestOptions,
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          setPlayAgain(false);
          toast.success("¡Felicidades! Recibirás un e-mail con la información");
        } else if (data.status == false && data.message == "no_folio") {
          setPlayAgain(true);
          toast.error("Boleta no encontrada, validar información.");
        } else if (data.status == false && data.message == "played") {
          setPlayAgain(true);
          toast.error("Boleta ya registrada, validar información.");
        } else {
          setPlayAgain(false);
          toast.info("Ya has participado.");
        }
        setLoadingAPI(false);
      })
      .catch((error) => {
        setLoadingAPI(false);
      });
    // }
  }

  function buildQueryString(params) {
    const queryString = Object.entries(params)
      .filter(
        ([key, value]) =>
          value !== null &&
          value !== undefined &&
          value !== "undefined" &&
          value !== "",
      )
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");
    return queryString;
  }
  function getData() {
    const emailParam = encodeURIComponent(email);
    const folioParam = encodeURIComponent(folio);
    const brandParam = encodeURIComponent(marca);
    // const idStoreParam = encodeURIComponent(idStore);

    // startSlotAnimation();

    const queryParams = {
      session: session,
      idStore: myIdStore,
      store: brandParam,
      folio: folioParam,
      email: emailParam,
    };

    const apiUrl = `https://bg.ps.digitag.cl/ps/internal/concurso/open.php?${buildQueryString(
      queryParams,
    )}`;

    setLoadingAPI(true);

    Promise.all([fetch(apiUrl)])
      .then(([response1]) => Promise.all([response1.json()]))
      .then(([stores]) => {
        setValue([
          <img
            src={`https://bg.ps.digitag.cl/bata-concurso/img/steps/${
              stores.selected["discount"] || "2x1"
            }.svg`}
            height="45px"
            width="45px"
          />,
          <img
            src={`https://bg.ps.digitag.cl/bata-concurso/img/steps/${
              stores.selected["discount"] || getRandomMultiplier()
            }.svg`}
            height="45px"
            width="45px"
          />,
          <img
            src={`https://bg.ps.digitag.cl/bata-concurso/img/steps/${
              stores.selected["discount"] || getRandomMultiplier()
            }.svg`}
            height="45px"
            width="45px"
          />,
        ]);

        if (stores.session === null || stores.session === "") {
          toast.info(
            "No hemos encontrado el folio indicado, valide los datos.",
          );

          setTimeout(() => {
            setCurrentWon(`Datos no coinciden, siga participando`);
            setPlayAgain(false);
          }, 2000);
        } else {
          Cookies.set("game_session", stores.session);
          setTimeout(() => {
            setCurrentWon(`Premio: ${stores.selected["name"]}`);
            setButtonPlay("Concursar nuevamente");
            setPlayAgain(true);
          }, 2000);
        }

        startSlotAnimation();
        setStores(stores);
        setLoading(false);
      })
      .finally(() => {
        setLoadingAPI(false);
      });
  }

  function handleFolioChange(event) {
    setFolio(event.target.value);
  }

  function handleEmailChange(event) {
    setEmail(event.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();

    setPlayAgain(false);

    if (folio.trim() === "" || !validateEmail(email)) {
      // toast.error(
      //   "Por favor, complete el correo electrónico y el número de boleta válido para continuar.",
      // );
      // return;
    }

    // if (!playAgain) {
    disabledButton();
    getData();
    // } else {
    //   wonGame();
    // }
  }

  function runGame() {}

  const showSlot = (
    <div className="col-12 col-sm-12 col-md-12 bt-slot-machine text-center text-sm-center text-md-center">
      <form onSubmit={handleSubmit}>
        <div className="digitag-slot mb-4 mt-3">
          <SlotCounter
            ref={counterRef}
            autoAnimationStart={false}
            duration={2}
            startValue={startValue}
            value={value}
            dummyCharacters={[
              <img
                className="itemCounter"
                height="45px"
                width="45px"
                src="https://bg.ps.digitag.cl/bata-concurso/img/steps/1.svg"
                alt=""
              />,
              <img
                className="itemCounter"
                height="45px"
                width="45px"
                src="https://bg.ps.digitag.cl/bata-concurso/img/steps/20.svg"
                alt=""
              />,
              <img
                className="itemCounter"
                height="45px"
                width="45px"
                src="https://bg.ps.digitag.cl/bata-concurso/img/steps/40.svg"
                alt=""
              />,
              <img
                className="itemCounter"
                height="45px"
                width="45px"
                src="https://bg.ps.digitag.cl/bata-concurso/img/steps/30.svg"
                alt=""
              />,
              <img
                className="itemCounter"
                height="45px"
                width="45px"
                src="https://bg.ps.digitag.cl/bata-concurso/img/steps/50.svg"
                alt=""
              />,
              <img
                className="itemCounter"
                height="45px"
                width="45px"
                src="https://bg.ps.digitag.cl/bata-concurso/img/steps/2x1.svg"
                alt=""
              />,
            ]}
          />
        </div>

        <div className="mb-4">{currentWon}</div>

        <div className="checkbox">
          <label>
            <input type="checkbox" name="terms" className="d-none" />
            <span>Al continuar acepto los </span>
            <a href={linkTerms} className="bt-link-primary">
              Términos y condiciones
            </a>
          </label>
        </div>
        {showPrincipalButton && (
          <button
            type="submit"
            className={`btn btn-default bt-btn btn-play btn-play-again ${
              isButtonDisabled ? "disabled" : ""
            }`}
          >
            {buttonPlay}
          </button>
        )}
        {Cookies.get("game_session") != "null" && (
          <>
            <div className={`form-group ${!playAgain ? "d-none" : ""}`}>
              <p>Completa tus datos para obtener tu premio.</p>
              <label htmlFor="email">Ingresa tu correo:</label>
              <input
                type="email"
                className="form-control"
                name="email"
                // value="ricardo@digitag.cl"
                placeholder="Ej: sorprendete@bata.cl"
                onChange={handleEmailChange}
              />
            </div>
            <div className={`row mt-4 mb-4 ${!playAgain ? "d-none" : ""}`}>
              <div className="col-md-5 col-5">
                <div className="form-group">
                  <label htmlFor="voucher">Tienda: </label>
                  <div className="selectStore">
                    <Select
                      // required="required"
                      value={selectedStore}
                      onChange={handleSelectChange}
                      // onChange={(selectedOption) =>
                      //   setSelectedStore(selectedOption)
                      // }
                      options={listStores.map((store) => ({
                        value: store.id_store,
                        label: store.id_store,
                      }))}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-7 col-7">
                <div className="form-group">
                  <label htmlFor="voucher">Ingresa tu número de boleta:</label>
                  <input
                    type="number"
                    className="form-control"
                    id="voucher"
                    name="voucher"
                    placeholder="Ej: 999321"
                    value={folio}
                    // value="3995549"
                    onChange={handleFolioChange}
                  />
                </div>
              </div>
            </div>
            <button
              type="button"
              onClick={() => wonGame()}
              className={`btn btn-default bt-btn btn-play btn-claim-prize ${
                !playAgain ? "d-none" : ""
              }`}
              disabled={playAgainDisabled} // Deshabilitar el botón según el estado
            >
              Reclamar premio
            </button>
          </>
        )}

        <Fireworks />
      </form>
    </div>
  );

  return (
    <>
      <header className="bt-header bt-header-product-display container-fluid">
        <div className="row">
          <div className="bt-back col-3 col-sm-3 col-md-3 text-center">
            <Link to={`/`} className="d-none">
              <img
                src="/img/icon-left-arrow.png"
                className="img-fluid img-responsive"
                alt="Back"
              />
            </Link>
          </div>

          <div className="bt-logo col-6 col-sm-6 col-md-6 text-center"></div>
        </div>

        <div className="row">
          <div
            className={`bt-search-bar bt-product-display-search col-12 col-sm-12 col-md-12 ${animation}`}
          ></div>
        </div>
      </header>
      <div className="container" id="bt-main-container">
        {loadingAPI ? (
          <div className="containerLoader">
            <BeatLoader color="#b03a3a" height={4} width={150} />
          </div>
        ) : (
          <></>
        )}
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 bt-text text-center text-sm-center text-md-center">
            <h1>
              <span className="bt-color-1">¡juega y </span>
              <span className="bt-color-2">gana!</span>
            </h1>

            <h2>Increíbles descuentos te esperan</h2>
          </div>
        </div>
        <div className="row">{showSlot}</div>
        <div className="row"></div>
      </div>
      <ToastContainer />
      <Modal
        style={customStyles}
        isOpen={isModalOpen}
        toggleModal={toggleModal}
      />
    </>
  );
}

export default Slot2;
