import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import { ClipLoader, RotateLoader, ClockLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import LazyLoad from "react-lazyload";
import SearchForm from "./SearchForm";
import Barcode from "react-barcode";
import "animate.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function ProductDetail() {
  const [animation, setAnimation] = useState("d-none");
  const { marca, tienda, idProduct, reference } = useParams();
  const [productos, setProductos] = useState([]);
  const [infoProduct, setInfoProduct] = useState([]);
  const [images, setImages] = useState([]);
  const [store, setStore] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const productsPerPage = 10;
  const pagesVisited = pageNumber * productsPerPage;

  const handleClickSearch = () => {
    if (animation.includes("active")) {
      setAnimation("d-none");
    } else {
      // setAnimation("animate__animated animate__bounce active");
      setAnimation("animate__animated active");
    }
  };

  useEffect(() => {
    if (marca !== null) {
      const body = document.querySelector("body");
      body.classList.add(marca);
    }

    // Cambiar el favicon según la clase del body
    if (document.body.classList.contains("wb")) {
      changeFavicon("https://weinbrenner.cl/img/favicon.ico");
    } else if (document.body.classList.contains("bg")) {
      changeFavicon("https://bubblegummers.cl/img/favicon.ico?1637328303");
    } else if (document.body.classList.contains("bt")) {
      changeFavicon(
        "https://batacl.vteximg.com.br/arquivos/batacl-favicon.ico",
      );
    } else if (document.body.classList.contains("ns")) {
      changeFavicon("https://northstar.cl/img/favicon.ico?1661443551");
    } else {
      changeFavicon(
        "https://batacl.vteximg.com.br/arquivos/batacl-favicon.ico",
      );
    }

    Promise.all([
      fetch(`https://bg.ps.digitag.cl/bata-catalogo/tunnel?shop=${marca}`),
      fetch("/json/stores.json"),
      fetch(
        `https://bg.ps.digitag.cl/bata-catalogo/get?store=${tienda}&sku=${cleanReference(
          reference,
        )}`,
      ),
      fetch(
        `https://bg.ps.digitag.cl/bata-catalogo/tunnel?shop=${marca}&id_product=${idProduct}`,
      ),
    ])
      .then(([response1, response2, response3, response4]) =>
        Promise.all([
          response1.json(),
          response2.json(),
          response3.json(),
          response4.json(),
        ]),
      )
      .then(([data, stores, infoProduct, imagesProduct]) => {
        setProductos(data);
        setInfoProduct(infoProduct);
        setImages(imagesProduct);
        const store = stores.find(
          (store) => store.id_store === parseInt(tienda),
        );
        setStore(store ? store["store_name"] : null);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setFilteredProducts(
      productos.filter(
        (producto) => parseInt(producto.id_product) === parseInt(idProduct),
      ),
    );
  }, [productos, search]);

  function changeFavicon(href) {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = href;
    document.getElementsByTagName("head")[0].appendChild(link);
  }

  const settingsSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false,
  };

  function cleanReference(string) {
    return string;
    if (string.length === 8) {
      string = string.substring(0, string.length - 1);
    } else if (string.length < 7) {
      string = string.padStart(7, "0");
    }
    return string;
  }

  const displayProducts = filteredProducts
    .slice(pagesVisited, pagesVisited + productsPerPage)
    .map((producto) => (
      <>
        <div className="bt-product-display col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="bt-product-size-chart-store-available bt-product-display-filter col-12 col-sm-12 col-md-12">
              <div className="slider">
                <Slider {...settingsSlider}>
                  {images.map((image) => (
                    <>
                      <div>
                        <LazyLoad
                          height={200}
                          once={true}
                          placeholder={
                            <img
                              src="/img/thumb.svg"
                              className="card-img-top img-fluid"
                              alt={image.id_image}
                            />
                          }
                        >
                          <img
                            src={
                              image.id_image == null
                                ? "/img/thumb.svg"
                                : image.url_image
                            }
                            className="card-img-top img-fluid"
                            alt={image.id_image}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/img/thumb.svg";
                            }}
                          />
                        </LazyLoad>
                      </div>
                    </>
                  ))}
                </Slider>
              </div>
              <div className="bt-product-description text-center">
                <h3 className="bt-product-description-brand">
                  {producto.manufacturer_name}
                </h3>

                <span className="bt-product-description-name">
                  {producto.product_name}
                </span>

                <div></div>
              </div>
              <p className="bt-product-size-chart-title text-center">
                <img src="/img/icon-store.png" alt="" className="img-fluid" />
                <span>Tallas disponibles en tienda</span>
              </p>
              {loading ? (
                <div className="col-md-12 text-center mb-4 mt-4 clockLoader">
                  <ClockLoader
                    color={"#333333"}
                    loading={loading}
                    css={override}
                    size={50}
                  />
                </div>
              ) : (
                <>
                  {infoProduct.response.STOCK.length > 0 &&
                  infoProduct.response.STOCK.filter(
                    (producto) => producto.TIPO === "STORE",
                  ).length > 0 ? (
                    <ul className="bt-size-list text-center">
                      {infoProduct.response.STOCK.filter(
                        (producto) => producto.TIPO === "STORE",
                      ).map((producto) => (
                        <li
                          className={producto.AVAILABLE <= 0 ? "d-none" : ""}
                          key={producto.SIZE_DISPLAY}
                        >
                          <a>{producto.SIZE_DISPLAY}</a>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <div className="alert alert-warning text-center">
                      No hay tallas disponibles para este producto
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          <div className="row">
            <div className="bt-product-size-chart-shipping-available bt-product-display-filter col-12 col-sm-12 col-md-12">
              <p className="bt-product-size-chart-title text-center">
                <img
                  src="/catalogo-iss/img/icon-shipping.png"
                  alt=""
                  className="img-fluid"
                />
                <span>Tallas disponibles para Delivery/ Pickup</span>
              </p>

              <ul className="bt-size-list text-center">
                {infoProduct.response.STOCK.length > 0 ? (
                  infoProduct.response.STOCK.filter(
                    (producto) => producto.TIPO === "SUMMARY",
                  ).map((producto) => (
                    <li className={producto.AVAILABLE <= 0 ? "d-none" : ""}>
                      <a>{producto.SIZE_DISPLAY}</a>
                    </li>
                  ))
                ) : (
                  <div className="alert alert-warning">
                    No hay tallas disponibles para este producto
                  </div>
                )}
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="bt-product-barcode col-12 col-sm-12 col-md-12 text-center">
              <span className="bt-barcode-top-text">Código del producto</span>
              <br />
              <Barcode value={cleanReference(producto.reference)} />
              <br />
              <span className="bt-barcode-mid-text">
                ¿Quieres comprar este producto?
              </span>

              <span className="bt-barcode-bottom-text mb-4">
                Acércate a uno de nuestros vendedores quien te ayudará en todo
                el proceso.
              </span>
            </div>
          </div>
        </div>
      </>
    ));

  const pageCount = Math.ceil(filteredProducts.length / productsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <>
      <header className="bt-header bt-header-product-display container-fluid">
        <div className="row">
          <div className="bt-back col-3 col-sm-3 col-md-3 text-center">
            <Link to={`/catalogo-iss/${marca}/${tienda}`}>
              <img
                src="/img/icon-left-arrow.png"
                className="img-fluid img-responsive"
                alt="Back"
              />
            </Link>
          </div>

          <div className="bt-logo col-6 col-sm-6 col-md-6 text-center"></div>

          <div className="bt-display-search col-3 col-sm-3 col-md-3 text-center">
            <img
              src="/img/icon-lupa.svg"
              className="img-fluid img-responsive"
              alt="search"
              onClick={handleClickSearch}
            />
          </div>
        </div>

        <div className="row">
          <div
            className={`bt-search-bar bt-product-display-search col-12 col-sm-12 col-md-12 ${animation}`}
          >
            <div className={`bt-form-wrapper ${animation}`}>
              <p>Búsqueda por código de producto (SKU)</p>
              <SearchForm marca={marca} tienda={tienda} />
            </div>
          </div>
        </div>
      </header>

      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-6 mb-4">
            <div className="input-group mycustom"></div>
          </div>
        </div>
        <div className="row mb-4">
          {loading ? (
            <div className="col-md-12 text-center mb-4 mt-4 clockLoader">
              <ClockLoader
                color={"#333333"}
                loading={loading}
                css={override}
                size={50}
              />
            </div>
          ) : (
            <>
              <div className="col-12 col-sm-12 col-md-12">
                <div className="row">{displayProducts}</div>
              </div>
            </>
          )}
        </div>
        <div className="bt-paginator-container d-none">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
            pageRangeDisplayed={2}
          />
        </div>
      </div>
    </>
  );
}

export default ProductDetail;
