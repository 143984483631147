import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import { ClockLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import SearchForm from "./SearchForm";
import { useLocation } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import LazyLoad from "react-lazyload";

function ProductsGrid() {
  const { marca, tienda } = useParams();
  const [productos, setProductos] = useState([]);
  const [store, setStore] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState("");
  const productsPerPage = 10;
  const pagesVisited = pageNumber * productsPerPage;

  useEffect(() => {
    if (marca !== null) {
      const body = document.querySelector("body");
      body.classList.remove(...body.classList);
      body.classList.add(marca);
    }

    // Cambiar el favicon según la clase del body
    if (document.body.classList.contains("wb")) {
      changeFavicon("https://weinbrenner.cl/img/favicon.ico");
    } else if (document.body.classList.contains("bg")) {
      changeFavicon("https://bubblegummers.cl/img/favicon.ico?1637328303");
    } else if (document.body.classList.contains("bt")) {
      changeFavicon(
        "https://batacl.vteximg.com.br/arquivos/batacl-favicon.ico",
      );
    } else if (document.body.classList.contains("ns")) {
      changeFavicon("https://northstar.cl/img/favicon.ico?1661443551");
    } else {
      changeFavicon(
        "https://batacl.vteximg.com.br/arquivos/batacl-favicon.ico",
      );
    }

    Promise.all([
      fetch(`https://bg.ps.digitag.cl/bata-catalogo/tunnel?shop=${marca}`),
      fetch("/json/stores.json"),
    ])
      .then(([response1, response2]) =>
        Promise.all([response1.json(), response2.json()]),
      )
      .then(([data, stores]) => {
        setProductos(data);
        const store = stores.find(
          (store) => store.id_store === parseInt(tienda),
        );
        setStore(store ? store["store_name"] : null);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });

    const params = new URLSearchParams(window.location.search);
    const searchQuery = params.get("s");

    if (searchQuery !== "") {
      setLoading(true);
      setSearch(searchQuery);
    } else {
      setLoading(true);
      setSearch("");
    }
  }, [window.location.search]);

  useEffect(() => {
    if (search == null) setSearch("");

    setFilteredProducts(
      productos.filter(
        (producto) =>
          producto.reference.toLowerCase().includes(search.toLowerCase()) ||
          producto.product_name.toLowerCase().includes(search.toLowerCase()) ||
          producto.attributes_group
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          producto.combination_group
            .toLowerCase()
            .includes(search.toLowerCase()),
      ),
    );
    // }
  }, [productos, search]);

  function changeFavicon(href) {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = href;
    document.getElementsByTagName("head")[0].appendChild(link);
  }

  function cleanReference(string) {
    return string;
    if (string.length === 8) {
      string = string.substring(0, string.length - 1);
    } else if (string.length < 7) {
      string = string.padStart(7, "0");
    }
    return string;
  }
  const displayProducts = filteredProducts
    .slice(pagesVisited, pagesVisited + productsPerPage)
    .map((producto) => (
      <>
        <div
          className="bt-product col-6 col-sm-6 col-md-6 text-center"
          key={producto.id_product}
        >
          <div className="bt-product-img">
            <LazyLoad
              height={200}
              once={true}
              placeholder={
                <img
                  src="/img/thumb.svg"
                  className="card-img-top img-fluid"
                  alt={producto.product_name}
                />
              }
            >
              <Link
                to={`/catalogo-iss/${marca}/${tienda}/${
                  producto.id_product
                }/${cleanReference(producto.reference)}`}
              >
                <img
                  src={
                    producto.id_image == null
                      ? "/img/thumb.svg"
                      : producto.url_image
                  }
                  className="card-img-top img-fluid"
                  alt={producto.product_name}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/img/thumb.svg";
                  }}
                />
              </Link>
            </LazyLoad>
          </div>

          <div className="bt-product-description">
            <h3 className="bt-product-description-brand">
              {producto.manufacturer_name}
            </h3>
            <span className="bt-product-description-name">
              {producto.product_name}
            </span>
            <Link
              to={`/catalogo-iss/${marca}/${tienda}/${
                producto.id_product
              }/${cleanReference(producto.reference)}`}
              className="bt-product-description-btn btn btn-primary"
            >
              Ver disponibilidad
            </Link>
          </div>
        </div>
      </>
    ));

  const pageCount = Math.ceil(filteredProducts.length / productsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  const Component = (
    <>
      <Tabs className="mt-2">
        <TabList className="text-center customTabs">
          <Tab>Filtro por Género</Tab>
          <Tab>Filtro por Tallas</Tab>
        </TabList>
        <TabPanel>
          {marca === "bg" ? (
            <div className="containerTab bt-filters">
              <ul className="bt-filter-options-list bt-genre-filter">
                <li>
                  <span
                    className={search === "Niño" ? "active" : ""}
                    onClick={() => setSearch("Niño")}
                  >
                    Niño
                  </span>
                </li>
                <li>
                  <span
                    className={search === "Niña" ? "active" : ""}
                    onClick={() => setSearch("Niña")}
                  >
                    Niña
                  </span>
                </li>
              </ul>
            </div>
          ) : (
            <div className="containerTab bt-filters">
              <ul className="bt-filter-options-list bt-genre-filter">
                <li>
                  <span
                    className={search === "Mujer" ? "active" : ""}
                    onClick={() => setSearch("Mujer")}
                  >
                    Mujer
                  </span>
                </li>
                <li>
                  <span
                    className={search === "Hombre" ? "active" : ""}
                    onClick={() => setSearch("Hombre")}
                  >
                    Hombre
                  </span>
                </li>
                <li>
                  <span
                    className={search === "Niñ" ? "active" : ""}
                    onClick={() => setSearch("Niñ")}
                  >
                    Niño
                  </span>
                </li>
              </ul>
            </div>
          )}
        </TabPanel>
        <TabPanel>
          <div className="containerTab bt-filters">
            {marca === "bg" ? (
              <ul className="bt-size-list bt-size-filter text-center">
                <li
                  className={search === "Niñ" ? "active" : ""}
                  onClick={() => setSearch(":20")}
                >
                  <span>20</span>
                </li>
                <li
                  className={search === "Niñ" ? "active" : ""}
                  onClick={() => setSearch(":21")}
                >
                  <span>21</span>
                </li>
                <li
                  className={search === "Niñ" ? "active" : ""}
                  onClick={() => setSearch(":22")}
                >
                  <span>22</span>
                </li>
                <li
                  className={search === "Niñ" ? "active" : ""}
                  onClick={() => setSearch(":23")}
                >
                  <span>23</span>
                </li>
                <li
                  className={search === "Niñ" ? "active" : ""}
                  onClick={() => setSearch(":24")}
                >
                  <span>24</span>
                </li>
                <li
                  className={search === "Niñ" ? "active" : ""}
                  onClick={() => setSearch(":25")}
                >
                  <span>25</span>
                </li>
                <li
                  className={search === "Niñ" ? "active" : ""}
                  onClick={() => setSearch(":26")}
                >
                  <span>26</span>
                </li>
                <li
                  className={search === "Niñ" ? "active" : ""}
                  onClick={() => setSearch(":27")}
                >
                  <span>27</span>
                </li>
                <li
                  className={search === "Niñ" ? "active" : ""}
                  onClick={() => setSearch(":28")}
                >
                  <span>28</span>
                </li>
                <li
                  className={search === "Niñ" ? "active" : ""}
                  onClick={() => setSearch(":29")}
                >
                  <span>29</span>
                </li>
                <li
                  className={search === "Niñ" ? "active" : ""}
                  onClick={() => setSearch(":30")}
                >
                  <span>30</span>
                </li>
                <li
                  className={search === "Niñ" ? "active" : ""}
                  onClick={() => setSearch(":31")}
                >
                  <span>31</span>
                </li>
                <li
                  className={search === "Niñ" ? "active" : ""}
                  onClick={() => setSearch(":32")}
                >
                  <span>32</span>
                </li>
                <li
                  className={search === "Niñ" ? "active" : ""}
                  onClick={() => setSearch(":33")}
                >
                  <span>33</span>
                </li>
                <li
                  className={search === "Niñ" ? "active" : ""}
                  onClick={() => setSearch(":34")}
                >
                  <span>34</span>
                </li>
              </ul>
            ) : (
              <ul className="bt-size-list bt-size-filter text-center">
                <li
                  className={search === ":35" ? "active" : ""}
                  onClick={() => setSearch(":35")}
                >
                  <span>35</span>
                </li>
                <li
                  className={search === ":36" ? "active" : ""}
                  onClick={() => setSearch(":36")}
                >
                  <span>36</span>
                </li>
                <li
                  className={search === ":37" ? "active" : ""}
                  onClick={() => setSearch(":37")}
                >
                  <span>37</span>
                </li>
                <li
                  className={search === ":38" ? "active" : ""}
                  onClick={() => setSearch(":38")}
                >
                  <span>38</span>
                </li>
                <li
                  className={search === ":39" ? "active" : ""}
                  onClick={() => setSearch(":39")}
                >
                  <span>39</span>
                </li>
                <li
                  className={search === ":40" ? "active" : ""}
                  onClick={() => setSearch(":40")}
                >
                  <span>40</span>
                </li>
                <li
                  className={search === ":41" ? "active" : ""}
                  onClick={() => setSearch(":41")}
                >
                  <span>41</span>
                </li>
                <li
                  className={search === ":42" ? "active" : ""}
                  onClick={() => setSearch(":42")}
                >
                  <span>42</span>
                </li>
                <li
                  className={search === ":43" ? "active" : ""}
                  onClick={() => setSearch(":43")}
                >
                  <span>43</span>
                </li>
                <li
                  className={search === ":44" ? "active" : ""}
                  onClick={() => setSearch(":44")}
                >
                  <span>44</span>
                </li>
              </ul>
            )}
          </div>
        </TabPanel>
      </Tabs>
    </>
  );

  return (
    <>
      <header className="bt-header container-fluid">
        <div className="row">
          <div className="bt-logo col-12 col-sm-12 col-md-12 text-center"></div>
        </div>

        <div className="row">
          <div className="bt-store-location col-12 col-sm-12 col-md-12">
            <img src="/img/icon-pin.png" className="img-fluid" alt="store" />
            <span>
              Tienda: {store} ({tienda})
            </span>
          </div>
        </div>

        <div className="row">
          <div className="bt-search-bar col-12 col-sm-12 col-md-12">
            <p>Búsqueda por código de producto (SKU)</p>

            <SearchForm marca={marca} tienda={tienda} />
            <div className="row">
              <div className="col-md-12">{Component}</div>
            </div>
            <form className="form-inline d-none" action="">
              <input
                type="text"
                className="input form-control rounded-pill"
                placeholder="Buscar por nombre, categoría o precio"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <button type="submit" className="btn btn-primary">
                Buscar
              </button>
            </form>
          </div>
        </div>
      </header>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-6 mb-4">
            <div className="input-group mycustom"></div>
          </div>
        </div>
        <div className="row mb-4">
          {loading ? (
            <div className="col-md-12 text-center mb-4">
              <div className="col-md-12 text-center mb-4 mt-4 clockLoader">
                <ClockLoader
                  color={"#333333"}
                  loading={loading}
                  css={override}
                  size={50}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="bt-products-list col-12 col-sm-12 col-md-12">
                <div className="row">
                  {displayProducts.length > 0 ? (
                    displayProducts
                  ) : (
                    <div className="col-md-12">
                      <div className="alert alert-warning text-center mb-4 mt-4">
                        No hay productos disponibles según la búsqueda
                        realizada.
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
        {loading ? (
          <></>
        ) : (
          <div className="bt-paginator-container">
            {displayProducts.length > 0 ? (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                activeClassName={"active"}
                pageRangeDisplayed={2}
              />
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default ProductsGrid;
