import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import LazyLoad from "react-lazyload";
import SearchForm from "./SearchForm";
import Barcode from "react-barcode";
import "animate.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function ProductDetail() {
  const [animation, setAnimation] = useState("d-none");
  const { marca, tienda, idProduct, reference } = useParams();
  const [productos, setProductos] = useState([]);
  const [infoProduct, setInfoProduct] = useState([]);
  const [images, setImages] = useState([]);
  const [store, setStore] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const [search, setSearch] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const productsPerPage = 10;
  const pagesVisited = pageNumber * productsPerPage;

  const handleClickSearch = () => {
    if (animation.includes("active")) {
      setAnimation("d-none");
    } else {
      setAnimation("animate__animated animate__bounce active");
    }
  };

  useEffect(() => {
    if (marca !== null) {
      const body = document.querySelector("body");
      body.classList.add(marca);
    }

    Promise.all([
      caches
        .open("myCache")
        .then((cache) =>
          cache.match(
            "https://www.bubblegummers.cl:8089/internal/api/query?id_query=58&token=9F2344F1AC9ADD40D4B2ADFA9280B18B6FA3F16634EE0B60F5B6758EEAE9CC71"
          )
        ),
      caches.open("myCache").then((cache) => cache.match("/json/stores.json")),
      caches
        .open("myCache")
        .then((cache) =>
          cache.match(
            `https://bg.ps.digitag.cl/bata-catalogo/get.php?store=${tienda}&sku=${cleanReference(
              reference
            )}`
          )
        ),
      caches
        .open("myCache")
        .then((cache) =>
          cache.match(
            `https://www.bubblegummers.cl:8089/internal/api/replace?output=json&replace=idProduct:${idProduct}&id_query=60&token=9F2344F1AC9ADD40D4B2ADFA9280B18B6FA3F16634EE0B60F5B6758EEAE9CC71`
          )
        )
    ])
      .then((responses) => {
        const fetchPromises = responses.map((response) => {
          if (response) {
            return response;
          } else {
            return fetch(response.url).then((response) => {
              caches
                .open("myCache")
                .then((cache) => cache.put(response.url, response.clone()));
              return response;
            });
          }
        });
        return Promise.all(fetchPromises);
      })
      .then((responses) => {
        const jsonPromises = responses.map((response) => response.json());
        return Promise.all(jsonPromises);
      })
      .then(([data, stores, infoProduct, imagesProduct]) => {
        setProductos(data);
        setInfoProduct(infoProduct);
        setImages(imagesProduct);
        const store = stores.find(
          (store) => store.id_store === parseInt(tienda)
        );
        setStore(store ? store["store_name"] : null);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setFilteredProducts(
      productos.filter((producto) => producto.id_product === "101910")
    );
  }, [productos, search]);

  const settingsSlider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: false
  };

  function cleanReference(string) {
    if (string.length === 8) {
      string = string.substring(0, string.length - 1);
    }
    return string;
  }

  const displayProducts = filteredProducts
    .slice(pagesVisited, pagesVisited + productsPerPage)
    .map((producto) => (
      <>
        <div className="bt-product-display col-12 col-sm-12 col-md-12">
          <div className="row">
            <div className="bt-product-size-chart-store-available bt-product-display-filter col-12 col-sm-12 col-md-12">
              <div className="slider">
                <Slider {...settingsSlider}>
                  {images.map((image) => (
                    <>
                      <div>
                        <LazyLoad
                          height={200}
                          once={true}
                          placeholder={
                            <img
                              src="/img/thumb.svg"
                              className="card-img-top img-fluid"
                              alt={image.id_image}
                            />
                          }
                        >
                          <img
                            src={
                              image.id_image == null
                                ? "/img/thumb.svg"
                                : image.url_image
                            }
                            className="card-img-top img-fluid"
                            alt={image.id_image}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = "/img/thumb.svg";
                            }}
                          />
                        </LazyLoad>
                      </div>
                    </>
                  ))}
                </Slider>
              </div>
              <div className="bt-product-description text-center">
                <h3 className="bt-product-description-brand">
                  {producto.manufacturer_name}
                </h3>

                <span className="bt-product-description-name">
                  {producto.product_name}
                </span>

                <div></div>
              </div>
              <p className="bt-product-size-chart-title">
                <img src="img/icon-store.png" alt="" className="img-fluid" />
                <span>Tallas disponibles en tienda</span>
              </p>

              <ul className="bt-size-list text-center">
                {infoProduct.response.STOCK.map((producto) => (
                  <>
                    <li
                      className={
                        producto.AVAILABLE <= 0 && producto.TIPO !== "STORE"
                          ? "d-none"
                          : ""
                      }
                    >
                      <a>{producto.SIZE_DISPLAY}</a>
                    </li>
                  </>
                ))}
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="bt-product-size-chart-shipping-available bt-product-display-filter col-12 col-sm-12 col-md-12">
              <p className="bt-product-size-chart-title">
                <img src="img/icon-shipping.png" alt="" className="img-fluid" />
                <span>Tallas disponibles para Delivery/ Pickup</span>
              </p>

              <ul className="bt-size-list text-center">
                {infoProduct.response.STOCK.map((producto) => (
                  <>
                    <li
                      className={
                        producto.AVAILABLE <= 0 && producto.TIPO === "STORE"
                          ? "d-none"
                          : ""
                      }
                    >
                      <a>{producto.SIZE_DISPLAY}</a>
                    </li>
                  </>
                ))}
              </ul>
            </div>
          </div>

          <div className="row">
            <div className="bt-product-barcode col-12 col-sm-12 col-md-12 text-center">
              <span className="bt-barcode-top-text">Código del producto</span>
              <br />
              <Barcode value={cleanReference(producto.reference)} />
              <br />
              <span className="bt-barcode-mid-text">
                Quieres comprar este producto?
              </span>

              <span className="bt-barcode-bottom-text mb-4">
                Acércate a uno de nuestros vendedores quien te ayudara en todo
                el proceso
              </span>
            </div>
          </div>
        </div>
      </>
    ));

  const pageCount = Math.ceil(filteredProducts.length / productsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;

  return (
    <>
      <header className="bt-header bt-header-product-display container-fluid">
        <div className="row">
          <div className="bt-back col-3 col-sm-3 col-md-3 text-center">
            <Link to={`/${marca}/${tienda}`}>
              <img
                src="/img/icon-left-arrow.png"
                className="img-fluid img-responsive"
                alt="Back"
              />
            </Link>
          </div>

          <div className="bt-logo col-6 col-sm-6 col-md-6 text-center"></div>

          <div className="bt-display-search col-3 col-sm-3 col-md-3 text-center">
            <img
              src="/img/icon-lupa.svg"
              className="img-fluid img-responsive"
              alt="search"
              onClick={handleClickSearch}
            />
          </div>
        </div>

        <div className="row">
          <div className="bt-search-bar bt-product-display-search col-12 col-sm-12 col-md-12">
            <div className={`bt-form-wrapper ${animation}`}>
              <p>Búsqueda por código de producto (SKU)</p>
              <SearchForm marca={marca} tienda={tienda} />
            </div>
          </div>
        </div>
      </header>

      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-6 mb-4">
            <div className="input-group mycustom"></div>
          </div>
        </div>
        <div className="row mb-4">
          {loading ? (
            <div className="col-md-12 text-center mb-4">
              <ClipLoader
                color={"#000000"}
                loading={loading}
                css={override}
                size={100}
              />
            </div>
          ) : (
            <>
              <div className="col-12 col-sm-12 col-md-12">
                <div className="row">{displayProducts}</div>
              </div>
            </>
          )}
        </div>
        <div className="bt-paginator-container d-none">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            pageCount={pageCount}
            onPageChange={changePage}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
            pageRangeDisplayed={2}
          />
        </div>
      </div>
    </>
  );
}

export default ProductDetail;
