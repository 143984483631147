import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Home from "./Home";
import HomeStore from "./HomeStore";
import Slot from "./Slot";
import Slot2 from "./Slot2";
import Slot3 from "./Slot3";
import ProductsGrid from "./ProductsGrid";
import ProductDetail from "./ProductDetail";
import ProductDetail2 from "./ProductDetail2";

import "./assets/css/styles.css";
import "./assets/css/stylesslot.css";

const router = createBrowserRouter([
  { path: "/ruleta", element: <Slot2 /> },
  { path: "/ruleta/:marca", element: <Slot2 /> },
  { path: "/ruleta/:marca/:idStore", element: <Slot2 /> },

  { path: "/cuponxstore", element: <Slot3 /> },
  { path: "/cuponxstore/:marca", element: <Slot3 /> },
  { path: "/cuponxstore/:marca/:idStore", element: <Slot3 /> },

  { path: "/ruleta2", element: <Slot /> },
  { path: "/ruleta2/:marca", element: <Slot /> },
  { path: "/ruleta2/:marca/:idStore", element: <Slot /> },
  { path: "/catalogo-iss", element: <Home /> },
  { path: "/catalogo-iss/:marca", element: <HomeStore /> },
  { path: "/catalogo-iss/:marca/:tienda", element: <ProductsGrid /> },
  {
    path: "/catalogo-iss/:marca/:tienda/:idProduct",
    element: <ProductDetail />,
  },
  {
    path: "/catalogo-iss/:marca/:tienda/:idProduct/:reference",
    element: <ProductDetail />,
  },
  {
    path: "cache/:marca/:tienda/:idProduct/:reference",
    element: <ProductDetail2 />,
  },
]);

const App = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "G-L0Q7Y3FV3L",
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <StrictMode>
      <RouterProvider router={router} />
    </StrictMode>
  );
};

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(<App />);
