import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { GetStore, GetAllStore } from "./Stores";
import { useParams } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";

function HomeStore() {
  const [animation, setAnimation] = useState("d-none");
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const { marca } = useParams();

  useEffect(() => {
    if (marca !== null) {
      const body = document.querySelector("body");
      body.classList.remove(...body.classList);
      body.classList.add(marca);
    }

    // Cambiar el favicon según la clase del body
    if (document.body.classList.contains("wb")) {
      changeFavicon("https://weinbrenner.cl/img/favicon.ico");
    } else if (document.body.classList.contains("bg")) {
      changeFavicon("https://bubblegummers.cl/img/favicon.ico?1637328303");
    } else if (document.body.classList.contains("bt")) {
      changeFavicon(
        "https://batacl.vteximg.com.br/arquivos/batacl-favicon.ico",
      );
    } else if (document.body.classList.contains("ns")) {
      changeFavicon("https://northstar.cl/img/favicon.ico?1661443551");
    } else {
      changeFavicon(
        "https://batacl.vteximg.com.br/arquivos/batacl-favicon.ico",
      );
    }
    Promise.all([fetch(`/json/${marca}.json`)])
      .then(([stores]) => Promise.all([stores.json()]))
      .then(([stores]) => {
        setStores(stores);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  function changeFavicon(href) {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = href;
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  const displayStores = stores.map((store) => (
    <>
      <div className="col-md-12">
        <div className="card mb-3">
          <div className="card-body">
            <h5 className="card-title store-title">
              <Link to={`/catalogo-iss/${marca}/${store.id_store}`}>
                {store.store_name}
              </Link>
            </h5>
          </div>
        </div>
      </div>
    </>
  ));

  return (
    <>
      <header className="bt-header bt-header-product-display container-fluid">
        <div className="row">
          <div className="bt-back col-3 col-sm-3 col-md-3 text-center">
            <Link to={`/`}>
              <img
                src="/img/icon-left-arrow.png"
                className="img-fluid img-responsive"
                alt="Back"
              />
            </Link>
          </div>

          <div className="bt-logo col-6 col-sm-6 col-md-6 text-center"></div>
        </div>

        <div className="row">
          <div
            className={`bt-search-bar bt-product-display-search col-12 col-sm-12 col-md-12 ${animation}`}
          ></div>
        </div>
      </header>
      <div className="container">
        <div className="row">{displayStores}</div>
      </div>
    </>
  );
}

export default HomeStore;
